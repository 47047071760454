import React from 'react';
import { navigate } from 'gatsby';
import { Flex } from 'rebass/styled-components';

import Text from '@/components/Text/Text';
import CTA from '@/components/CTA/CTA';

const NotFound = () => (
  <Flex
    as="main"
    flexGrow={1}
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
  >
    <Text as="h2" textStyle="secondaryHeading" marginBottom="4rem">
      La page que vous avez demandé n&apos;existe pas
    </Text>
    <CTA message="Retour au site" onClick={() => navigate('/')} />
  </Flex>
);

export default NotFound;
